<template>
    <div :class="!userListshow ? 'investigate' : 'investigate hidden' ">
        <div class="content">
            <div class="orderInfo">
                <div class="item">
                    <span>产品名称：</span>
                    <span>{{orderInfo.ProductName}}</span>
                </div>
                <div class="item">
                    <span>总订购数量：</span>
                    <span>{{orderInfo.TotalCount}}套</span>
                </div>
                <div class="item">
                    <span>订单编号：</span>
                    <span>{{orderInfo.OrderID}}</span>
                </div>
                <div class="item">
                    <span>下单时间：</span>
                    <span>{{orderInfo.OrderDate}}</span>
                </div>
            </div>
            <div class="userForm" v-for="(item, index) in formArr" :key="index">
                <van-form  :ref="`form${index}`">
                    <div class="userInfo">
                        <div class="formItem">
                            <van-field label="用户" v-model.trim="item.username" placeholder="请输入用户姓名并进行搜索" @input="searchChange($event, index)" />
                            <van-button class="search" type="info" native-type='button' size="small" @click="getUserInfo(item.username, orderInfo.ProductName, index)">搜索</van-button>
                        </div>
                        <div class="formItem item2">
                            <van-field label="姓名:" v-model="item.name" readonly :rules="[{ required: true }]" />
                            <van-field label="性别:" v-model="item.ex3" readonly :rules="[{ required: true }]" />
                        </div>
                        <div class="formItem item2">
                            <van-field label="出生年月:" v-model="item.ex4" readonly />
                        </div>
                    </div>
                    <div class="course">
                        <div class="marbot">
                            <van-field label="主疗程：" v-model="orderInfo.ProductName" readonly/>
                        </div>
                        <div class="marbot" v-if="specialShow">
                            <van-field
                                readonly
                                clickable
                                name="picker"
                                :value="item.Improve"
                                label="调理问题："
                                placeholder="请选择调理问题"
                                :rules="[{ required: true}]"
                                @click="specialShowPicker = true"
                            />
                            <!--  :rules="[{ required: true}]" -->
                        </div>
                        <van-popup v-model="specialShowPicker" position="bottom">
                            <van-picker 
                                show-toolbar
                                :columns="specialColumns"
                                @confirm="specialConfirm($event, index)"
                                @cancel="specialShowPicker = false"
                            />
                        </van-popup>

                        <div class="marbot">
                            <van-field
                                readonly
                                clickable
                                name="picker"
                                :value="item.MainDemands"
                                label="其他诉求："
                                placeholder="请点击选择诉求"
                                @click="appealShowPicker = true"
                            />
                            <!--  :rules="[{ required: true}]" -->
                        </div>
                        <van-popup v-model="appealShowPicker" position="bottom">
                            <van-picker 
                                show-toolbar
                                :columns="appealColumns"
                                @confirm="appealonConfirm($event, index)"
                                @cancel="appealShowPicker = false"
                            />
                        </van-popup>
                        <div class="questionnaire" v-for="(el, i) in item.radioArr" :key="i">
                            <div class="title">
                                <div class="mark"></div>
                                <div>{{el.title}}</div>
                            </div>
                            <van-field name="radio" :label="k.QuestTitle" v-for="(k, ix) in el.radioItem" :key="ix" :rules="[{ required: true, message: '请进行选择'}]" @click="fieChange">
                                <template #input>
                                    <van-radio-group v-model="k.value" direction="horizontal" >
                                        <van-radio name="true">是</van-radio>
                                        <van-radio name="false">否</van-radio>
                                    </van-radio-group>
                                </template>
                            </van-field>
                        </div>
                        <div class="flexSty">
                            <div class="mark"></div>
                            <van-field
                                type="digit"
                                v-model="item.OrderNum"
                                @change="fieChange"
                                label="定制套数："
                                placeholder="请填写定制套数"
                                :rules="[{ required: true,}]"
                            />
                            <div>套</div>
                        </div>
                        <div class="flexSty">
                            <div class="mark"></div>
                            <van-field
                                v-model="item.ReceAddress"
                                label="收货地址："
                                @change="fieChange"
                                placeholder="请填写收货地址"
                                :rules="[{ required: true}]"
                            />
                        </div>
                        <div class="flexSty">
                            <div class="mark"></div>
                            <van-field
                                v-model="item.ReceName"
                                label="收件人："
                                @change="fieChange"
                                placeholder="请填写收件人"
                                :rules="[{ required: true}]"
                            />
                        </div>
                        <div class="flexSty">
                            <div class="mark"></div>
                            <van-field
                                type="tel"
                                v-model="item.RecePhone"
                                label="手机号："
                                @change="fieChange"
                                placeholder="请填写手机号"
                                :rules="[{ required: true}]"
                            />
                        </div>
                        <div class="flexSty">
                            <div class="mark"></div>
                            <van-field
                                v-model="item.Remark"
                                rows="1"
                                @change="fieChange"
                                autosize
                                label="其他备注"
                                type="textarea"
                                placeholder="请填写备注"
                            />
                        </div>
                    </div>
                </van-form>
                <div class="cross" v-if="index !== 0" @click="deleteUser(index)">
                    <van-icon name="cross" />
                </div>
            </div>
            <div class="btn">
                <van-button type="info" @click="addUser" v-if="formArr.length < 3" >添加用户</van-button>
                <van-button class="addbtn" type="info" @click="Submit" :loading='subLoading'>提交</van-button>
            </div>
            <!-- 客户选择弹窗 -->
            <van-overlay :show="userListshow">
                <div class="wrapper" @click="close">
                    <div class="userList" @click.stop>
                        <div class="head">
                            <div class="icon">
                                <img class="img" :src="iconUrl" alt="">
                            </div>
                        </div>
                        <div class="listInfo">
                            <div class="title">
                                <div class="tip"></div>
                                <div>信息确认</div>
                            </div>
                            <div class="listItem">
                                <van-radio-group v-model="radio" v-for="item in userList" :key="item.autoid" :disabled="item.disabled">
                                    <van-cell-group>
                                        <van-cell clickable @click="!item.disabled ? radio = item.autoid : ''">
                                            <template #right-icon>
                                                <div class="userData">
                                                    <div>
                                                        <span class="leb">姓名 | </span>
                                                        <span>{{item.ex1}}</span>
                                                    </div>
                                                    <div>
                                                        <span class="leb">出生年月 | </span>
                                                        <span>{{item.ex4}}</span>
                                                    </div>
                                                    <div>
                                                        <span class="leb">性别 | </span>
                                                        <span>{{item.ex3}}</span>
                                                    </div>
                                                </div>
                                                <van-radio shape="square" :name="item.autoid" />
                                            </template>
                                        </van-cell>
                                    </van-cell-group>
                                </van-radio-group>
                            </div>
                            <div class="btn userbt">
                                <van-button class="addbtn" native-type='button' type="info" @click="userConfirm">确认</van-button>
                            </div>
                        </div>
                    </div>
                </div>
            </van-overlay>
        </div>
    </div>
</template>

<script>
import { queryMallApi } from '@/api/index'
import { sortArr } from '@/util/validate'

export default {
    components: {
        
    },
    data() {
        return {
            orderInfo: {
                ProductName: '个性化女性荷尔蒙管理'
            },
            formArr: [
                {   
                    username: '',
                    MainDemands: '',
                    OrderNum:'',
                    ReceAddress: '',
                    ReceName: '',
                    RecePhone: '',
                    Remark: '',
                    radioArr: [],
                }
            ],
            radioArrData: [],
            userList: [],
            specialShow: false, // 特殊主疗程输入内容开关
            specialShowPicker: false, //改善功效开关
            specialColumns: [],//改善功效选择数据
            appealShowPicker: false, //诉求选择器开关
            appealColumns: [], //诉求选择数据
            userListshow: false,
            radio: null,
            filterDataIndex: null,
            iconUrl: `${this.$imgDomain}/www/HealthingCore/userList.png`,
            domainUrl: '',
            subLoading: false,
            Validation: false,
        };
    },
    props:{
        
    },
    created() {
        this.domainUrl = this.$route.query.domain;        
        const queryParams = this.$route.query;
        this.getOrderDetil(queryParams.orderId);
    },
    mounted() {
    },
    methods: {
        // 获取订单详情
        getOrderDetil(orderId){
            this.$toast.loading({
                duration: 5000,
                message: '加载中',
            })

            const formData = new FormData();
            formData.append('action', 'GetProductDetailInfoById');
            // formData.append('orderId', orderId);
            formData.append('orderId', '2023040127897104');

            queryMallApi(formData).then(res => {
                const { returnObj } = res
                if(returnObj) {
                    this.orderInfo = returnObj[0];
                    this.$toast.success({
                        message: '订单获取成功',
                    })
                    //根据产品 获取问卷列表/诉求数据
                    this.getQuestionnaireList(returnObj[0].ProductName);
                    // 特殊疗程处理
                    this.specialTreatment(this.orderInfo)
                } else {
                    this.$toast.fail({
                        message: '订单获取失败',
                    })
                }
            }).catch(error => {
                this.specialTreatment(this.orderInfo)
            })
        },
        // 获取问卷列表信息/诉求数据
        getQuestionnaireList(ProductName){
            const formData = new FormData();
            formData.append('action', 'GetQuestionList');
            formData.append('proName', ProductName);
            console.log('formData',formData)
            queryMallApi(formData).then(res => {
                const { returnObj } = res
                if(returnObj) {
                    if(returnObj.Question.length > 0){
                        const reportData = sortArr(returnObj.Question, 'QuestType');
                        let handleAfter = [];
                        reportData.forEach(item => {
                            let raiodObj = {
                                title: '',
                                radioItem: []
                            };

                            raiodObj.title = item[0].QuestType;
                            raiodObj.radioItem = item;
                            handleAfter.push(raiodObj)
                        })
                        this.radioArrData = handleAfter;

                        this.formArr.map(item => {
                            item.radioArr = handleAfter;
                        })
                    }
                    
                    // 诉求数据
                    this.handlingAppeals(returnObj.MainDemonds);
                } else {
                    this.$toast.fail({
                        message: '问卷列表获取失败',
                    })
                }
            })
        },
        // 处理诉求数据
        handlingAppeals(data){
            const appealsOps = data.map(item => {return item.MainName} );
            this.appealColumns = appealsOps;
        },
        // 诉求选择监听
        appealonConfirm(value, index){
            this.formArr[index].MainDemands = value;
            this.appealShowPicker = false;
        },
        // 特殊主疗程处理
        specialTreatment(data){
            const { ProductName} = data;
            if(ProductName.indexOf('女性荷尔蒙') != -1) {
                this.specialShow = true;
                this.specialColumns = ['子宫肌瘤','延缓更年期','卵巢抗衰']
                return
            }
            if(ProductName.indexOf('甲状腺') != -1) {
                this.specialShow = true;
                this.specialColumns = ['甲状腺功能减退','甲状腺功能亢进']
                return
            }
            if(ProductName.indexOf('肝胆') != -1) {
                this.specialShow = true;
                this.specialColumns = ['肝炎','脂肪肝']
                return
            }
            if(ProductName.indexOf('睡眠') != -1) {
                this.specialShow = true;
                this.specialColumns = ['失眠','抑郁']
                return
            }
        },
        // 特殊主疗程弹窗选择
        specialConfirm(value, index){
            this.formArr[index].Improve = value;
            this.specialShowPicker = false;
        },
        // 查询内容监听
        searchChange(value,index){
            this.formArr[index].username = value;
            this.$forceUpdate();
        },
        // 查询用户信息
        getUserInfo(userName, ProductName,index){
            this.$toast.loading({
                duration: 5000,
                message: '查询中',
            })
            const formData = new FormData();
            formData.append('action', 'GetUserExpandInfoByWebsiteowner');
            formData.append('websiteowner', this.orderInfo.LevelIcon);
            formData.append('proname', ProductName);
            formData.append('Userexpandname', userName);
            queryMallApi(formData).then(res => {
                const { returnObj } = res
                if(returnObj) {
                    this.userList = returnObj;

                    this.filterDataIndex = index;
                    this.userList.forEach((item)=>{
                        item.disabled = false;
                        this.formArr.forEach(el => {
                            if(el.autoid && item.autoid == el.autoid) {
                                item.disabled  = true;
                            }
                        })
                    });
                    
                    this.$toast.clear();
                    this.userListshow = true;
                } else {
                    this.$toast.fail({
                        message: '用户获取失败',
                    })
                }
            }).catch(err => {
                this.$toast.fail({
                    message: err,
                })
            })
        },
        // 添加用户表单
        addUser(){
            this.formArr.push({
                radioArr: this.radioArrData,
            });
        },
        // 删除用户表单
        deleteUser(index){
            this.formArr.splice(index, 1);
        },
        // 客户确认
        userConfirm(){
            if(!this.radio) {
                this.$toast('请选择用户')
                return 
            }
            let checkData = this.userList.find((e) => {
                return e.autoid == this.radio
            })
            let operaObj = JSON.parse(JSON.stringify(this.formArr[this.filterDataIndex]));
            const newObj = {...operaObj, ...checkData};
            this.formArr[this.filterDataIndex] = newObj;
            this.formArr[this.filterDataIndex].ReceName = this.formArr[this.filterDataIndex].ex1
            this.formArr[this.filterDataIndex].name = this.formArr[this.filterDataIndex].ex1
            this.radio = null;
            this.userListshow = false;
        },
        // 提交校验
        Submit(){
            const p1 = new Promise((resolve, reject) => {
                if(this.$refs['form0']) {
                    this.$refs['form0'][0].validate().then(()=>{
                        // 验证通过
                        resolve();
                    }).catch(()=>{
                        //验证失败
                        reject();
                    })
                } else {
                    resolve();
                }
            });
            const p2 = new Promise((resolve, reject) => {
                if(!this.$refs['form1']) {
                    resolve();
                } else {
                    this.$refs['form1'][0].validate().then(()=>{
                        // 验证通过
                        resolve();
                    }).catch(()=>{
                        //验证失败
                        reject();
                    })
                }
            });
            const p3 = new Promise((resolve, reject) => {
                if(!this.$refs['form2']) {
                    resolve();
                } else {
                    this.$refs['form2'][0].validate().then(()=>{
                        // 验证通过
                        resolve();
                    }).catch(()=>{
                        //验证失败
                        reject();
                    })
                }
                
            });
            Promise.all([p1, p2, p3]).then(() => {
                let totalNum = 0;
                this.formArr.map((item, index) => {
                    item.OrderId = this.orderInfo.OrderID;
                    let QuestId = [];
                    item.radioArr.forEach(el => {
                        el.radioItem.forEach(val => {
                            QuestId.push(
                                {
                                    AutoId: val.AutoId,
                                    state: val.value
                                }
                            )
                        })
                    })
                    totalNum+=Number(item.OrderNum)
                    item.QuestId = JSON.stringify(QuestId);
                })
                console.log(this.formArr)
                if(totalNum != this.orderInfo.TotalCount) {
                    this.$toast({
                        message: '用户定制数量与商品总数不一致',
                    })
                    return 
                }

                this.subLoading = true;

                let formCopy = JSON.parse(JSON.stringify(this.formArr))
                formCopy.forEach(item => {
                    item.DataValue = item.value;
                    item.ReportTypeItem = item.ex23;
                    delete item.radioArr;
                    delete item.create_time;
                    delete item.disabled;
                    delete item.type;
                    delete item.ex1;
                    delete item.ex2;
                    delete item.ex3;
                    delete item.ex4;
                    delete item.ex7;
                    delete item.autoid;
                    delete item.username;
                    delete item.value;
                })

                // 请求接口
                this.upDataOrder(formCopy);
            }).catch((error) =>{

            })            
        },
        // 提交接口
        upDataOrder(data){
            this.$toast.loading({
                duration: 5000,
                message: '提交中',
            })
            const formData = new FormData();
            formData.append('action', 'InsertAgentOrder');
            formData.append('data', JSON.stringify(data));
            queryMallApi(formData).then(res => {
                const { isSuccess, errmsg } = res
                if(isSuccess) {
                    this.$toast.success({
                        message: errmsg,
                    })
                    this.subLoading = false;
                    
                    // 跳到商城我的页面
                    const url = `https://${this.domainUrl}/customize/comeoncloud/Index.aspx?key=PersonalCenter`;
                    location.assign(url);
                } else {
                    this.$toast.fail({
                        message: errmsg,
                    })
                }
            })
        },
        fieChange(){
            this.$forceUpdate()
        },
        close(){
            this.userListshow = false
        }
    },
    watch: {
        
    },
    computed: {
        
    },
}
</script>
<style lang='scss' scoped>
.investigate {
    padding-top: 40px;
    height: 100%;
    background-color: #D3F8FF;
    .content {
        width: 100%;
        border-radius: 20px 20px 0 0;
        border-top: 1px solid #fff;
        background-color: #f1f1f1;
        padding: 15px;
        .orderInfo {
            display: flex;
            flex-wrap: wrap;
            color: #888888;
            font-weight: 700;
            .item {
                width: 50%;
                display: flex;
                align-items: center;
                margin-bottom: 10px;
            }
        }
        .userForm {
            background: #fff;
            padding: 10px;
            border-radius: 10px;
            box-shadow: 4px 5px 7px #C0C0C0;
            margin-bottom: 20px;
            position: relative;
            .userInfo {
                display: flex;
                flex-wrap: wrap;
                color: #888888;
                .formItem {
                    margin-bottom: 10px;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    /deep/ .van-field__label {
                        color: #888888;
                        width: auto;
                    }
                    /deep/ .van-button {
                        background-color: #FF9480;
                        border: 1px solid #FF9480;
                    }
                    /deep/ .van-cell {
                        width: 70%;
                    }
                    /deep/ .van-cell::after {
                        display: none;
                    }
                    .search {

                    }
                }
                .item2 {
                    /deep/ .van-cell {
                        padding: 0;
                    }
                    /deep/ .van-field__label {
                        margin-right: 0;
                        width: auto;
                    }
                    /deep/ .van-cell__value {
                        margin-left: 10px;
                    }
                }
            }
            .course {
                color: #6D6D6D;
                font-size: 14px;
                /deep/ .van-cell {
                    padding: 0;
                }
                /deep/ .van-field__label {
                    width: 5.6em;
                }
                /deep/ .van-cell__title {
                    margin-right: 0;
                    color: #6D6D6D;
                }
                .mark {
                    width: 10px;
                    height: 10px;
                    border-radius: 1px;
                    background-color: #D3F8FF;
                    margin-right: 10px;
                }
                .marbot {
                    margin-bottom: 10px;
                }
                .questionnaire {
                    margin-bottom: 5px;
                    .title {
                        display: flex;
                        align-items: center;
                        margin-bottom: 8px;
                    }
                    /deep/ .van-cell {
                        margin-bottom: 8px;
                    }
                    /deep/ .van-cell__title {
                        padding-left: 20px;
                        width: 60%;
                    }
                    /deep/ .van-cell__value {
                        width: 40%;
                    }
                    // /deep/ .van-field__label {
                    //     width: 6.6em;
                    // }
                    /deep/ .van-radio__icon {
                        font-size: 16px;
                        .van-icon {
                            line-height: 1;
                        }
                    }
                }
                .flexSty {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
                    /deep/ .van-cell {
                        width: 70%;
                    }
                }
            }
            .cross {
                position: absolute;
                right: 2px;
                top: 0;
                font-size: 30px;
            }
        }
        .btn {
            display: flex;
            justify-content: space-around;
            /deep/ .van-button {
                width: 90px;
            }
            .addbtn {
                font-size: 18px;
                background: #FF9480;
                border-color: #FF9480;
            }
        }
        .wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            .userList {
                width: 80%;
                height: 60%;
                background: #fff;
                border-radius: 20px;
                overflow: hidden;
                .head {
                    height: 25%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: #E5F1F7;
                }
                .listInfo {
                    padding: 10px;
                    height: 75%;
                    .title {
                        color: #888888;
                        font-size: 18px;
                        display: flex;
                        align-items: center;
                        font-weight: 700;
                        .tip {
                            width: 5px;
                            height: 18px;
                            margin-right: 10px;
                            background-color: #FF9480;
                            border-radius: 2px;
                        }
                    }
                    .listItem {
                        height: 200px;
                        height: calc(100% - 108px);
                        overflow-y: scroll;
                        /deep/ .van-cell {
                            font-size: 14px;
                            justify-content: space-between;
                            padding: 10px 5px;
                        }
                        .userData {
                            width: 90%;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            color: #6D6D6D;
                            .leb {
                                color: #888888;
                            }
                        }
                    }
                    .userbt {
                        /deep/ .van-button {
                            width: 50%;
                            border-radius: 5px;
                            margin: 20px 0;
                        }
                    }
                    
                }
            }
        }
    }
}
.hidden {
    overflow: hidden;
}
</style>